<template>
  <b-card>
    <b-overlay
      :show="loading"
      no-wrap
      :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
    />
    <b-card-title class="text-center mb-3 mt-2">
      {{ d.name }}
    </b-card-title>
    <hr>
    <b-row>
      <b-col
        lg="4"
        class="pt-1 pb-1 d-flex justify-content-center"
      >
        <div class="d-inline-block text-center">
          <small class="text-muted font-weight-medium">Вход</small>
          <h4 class="mt-50 mb-0">
            {{ d.enter }}
          </h4>
        </div>
      </b-col>
      <b-col
        lg="4"
        class="pt-1 pb-1 d-flex justify-content-center"
      >
        <div class="d-inline-block text-center">
          <small class="text-muted font-weight-medium">Дата контракта</small>
          <h4 class="mt-50 mb-0">
            {{ d.date }}
          </h4>
        </div>
      </b-col>
      <b-col
        lg="4"
        class="pt-1 pb-1 d-flex justify-content-center"
      >
        <div class="d-inline-block text-center">
          <small class="text-muted font-weight-medium">Статус</small>
          <h4 class="mt-50 mb-0">
            {{ d.status }}
          </h4>
        </div>
      </b-col>
      <b-col
        lg="4"
        class="pt-1 pb-1 d-flex justify-content-center"
      >
        <div class="d-inline-block text-center">
          <small class="text-muted font-weight-medium">Активность</small>
          <h6 :class="`mt-50 mb-0 text-${d.activeColor || 'primary'}`">
            {{ d.active }}
          </h6>
          <v-select
            v-model="xparam1"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :get-option-label="option => option.text"
            :reduce="val => val.value"
            :options="activeOptions"
            :filterable="false"
            :clearable="false"
            class="active-select"
          />
          <b-button
            v-if="d.activeButton == 1"
            :variant="d.activeButtonColor"
            :disabled="!xparam1"
            style="margin-top: 10px;"
            @click="activeButton"
            size="sm"
          >Продлить активность</b-button>
        </div>
      </b-col>
      <b-col
        lg="4"
        class="pt-1 pb-1 d-flex justify-content-center"
      >
        <div class="d-inline-block text-center">
          <small class="text-muted font-weight-medium">Сумма контракта</small>
          <h4 class="mt-50 mb-0">
            {{ d.status2 }}
          </h4>
          <b-button
            v-if="d.status2Button == 1"
            :variant="d.activeButtonColor"
            style="margin-top: 10px;"
            @click="enterButton(d)"
            size="sm"
          >{{ d.status2ButtonText }}</b-button>
        </div>
      </b-col>
      <b-col
        lg="4"
        class="pt-1 pb-1 d-flex justify-content-center"
      >
        <div class="d-inline-block text-center pr-1">
          <small class="text-muted font-weight-medium">Кол-во акт.ЛП</small>
          <h4 class="mt-50 mb-0">
            {{ d.count }}
          </h4>
          <b-button
            variant="primary"
            style="margin-top: 10px;"
            @click="refreshOrderButton"
            size="sm"
          >Повторная покупка</b-button>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
/* eslint-disable */
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BButton,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    vSelect,
    BButton,
    BOverlay,
  },
  props: {
    data: {
      type: Object,
      required: false,
      default: () => {},
    },
    api: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup(props, { root }) {
    const d = ref(JSON.parse(JSON.stringify(props.data)))
    const loading = ref(false)
    const activeOptions = ref([])
    const xparam1 = ref(null)

    if (props.api) {
      loading.value = true
      store.dispatch('app/fetchData', props.api).then(res => {
        const { data, success } = res.data

        if (success) {
          d.value = data[0]
        }

        loading.value = false

        store.dispatch('app/fetchData', 'json/lk/service/data/list/active').then(r => {
          activeOptions.value = r.data.data
          xparam1.value = activeOptions.value[0].value
        })

      }).catch(() => {
        loading.value = false
      })
    }

    const activeButton = () => {
      root.$swal({
      text: 'Подать заявку на продление активации?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Да',
      cancelButtonText: 'Нет',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1',
      },
      buttonsStyling: false,
      showLoaderOnConfirm: true,
      preConfirm() {
        return store.dispatch('app/sendData', {
          api: 'https://api.hoglabest.com/api/v1/json/lk/buyer/contract/set/active',
          data: { xparam1: xparam1.value },
        })
          .then(res => res)
          .catch(err => err.response)
      },
    }).then(result => {
      if (result.isConfirmed) {
        const { data, success } = result.value.data
        if (success) {
          // eslint-disable-next-line no-underscore-dangle
          return root.$swal({
            icon: 'success',
            text: data.msg,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          }).then(() => {
            loading.value = true
            store.dispatch('app/fetchData', props.api).then(res => {
              const { data, success } = res.data

              if (success) {
                d.value = data[0]
              }

              loading.value = false
            }).catch(() => {
              loading.value = false
            })
          })
        } else {
          // eslint-disable-next-line no-underscore-dangle
          return root.$swal({
            icon: 'error',
            text: data.msg,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          }).then(() => false)
        }
      }
    })
    }

    const enterButton = d => {
      root.$swal({
      text: d.status2ButtonText,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Да',
      cancelButtonText: 'Нет',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1',
      },
      buttonsStyling: false,
      showLoaderOnConfirm: true,
      preConfirm() {
        return store.dispatch('app/fetchData', 'https://api.hoglabest.com/api/v1/json/lk/buyer/contract/set/enter')
          .then(res => res)
          .catch(err => err.response)
      },
    }).then(result => {
      if (result.isConfirmed) {
        const { data, success } = result.value.data
        if (success) {
          // eslint-disable-next-line no-underscore-dangle
          return root.$swal({
            icon: 'success',
            text: data.msg,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          }).then(() => {
            loading.value = true
            store.dispatch('app/fetchData', props.api).then(res => {
              const { data, success } = res.data

              if (success) {
                d.value = data[0]
              }

              loading.value = false
            }).catch(() => {
              loading.value = false
            })
          })
        } else {
          // eslint-disable-next-line no-underscore-dangle
          return root.$swal({
            icon: 'error',
            text: data.msg,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          }).then(() => false)
        }
      }
    })
    }

    const refreshOrderButton = () => {
      root.$swal({
      text: 'Повторная покупка',
      iconHtml: `<svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-cart"><circle cx="9" cy="21" r="1"></circle><circle cx="20" cy="21" r="1"></circle><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path></svg>`,
      input: 'number',
      inputPlaceholder: 'Сумма, руб.',
      showCancelButton: true,
      confirmButtonText: 'Ок',
      cancelButtonText: 'Закрыть',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1',
      },
      buttonsStyling: false,
      showLoaderOnConfirm: true,
      preConfirm(val) {
        return store.dispatch('app/sendData', {
          api: 'https://api.hoglabest.com/api/v1/json/lk/buyer/contract/set/povtor',
          data: { xparam1: val },
        })
        .then(res => res)
        .catch(err => err.response)
      },
    }).then(result => {
      if (result.isConfirmed) {
        const { data, success } = result.value.data
        if (success) {
          // eslint-disable-next-line no-underscore-dangle
          return root.$swal({
            icon: 'success',
            text: data.msg,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          }).then(() => {
            loading.value = true
            store.dispatch('app/fetchData', props.api).then(res => {
              const { data, success } = res.data

              if (success) {
                d.value = data[0]
              }

              loading.value = false
            }).catch(() => {
              loading.value = false
            })
          })
        } else {
          // eslint-disable-next-line no-underscore-dangle
          return root.$swal({
            icon: 'error',
            text: data.msg,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          }).then(() => false)
        }
      }
    })
    }



    return {
      d,
      loading,
      activeOptions,
      xparam1,
      activeButton,
      enterButton,
      refreshOrderButton,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.active-select.v-select.vs--single .vs__selected {
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}

.active-select .vs__selected-options {
    height: 30px;
}

.active-select .vs__search {
  z-index: -1;
}

.swal2-icon.swal2-icon-show {
  color: #A5DC86;
  border-color: rgba(165, 220, 134, 0.25);
}
.swal2-icon.swal2-icon-show.swal2-question {
  border-color: #c9dae1;
  color: #87adbd;
}

.swal2-input {
  width: 100%;
  max-width: 100%!important;
  margin-top: 1.5rem;
}
</style>
