<template>
  <b-card>
    <b-overlay
      :show="loading"
      no-wrap
      :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
    />
    <b-row>
      <b-col
        v-if="d"
        sm="12"
      >
        <div class="statlegend">
          <table
            width="100%"
            cellspacing="0"
            cellpadding="0"
          >
            <tr>
              <td
                colspan="3"
                align="center"
              >
                <b>Баланс веток</b><hr>(Оплаченный объем)
              </td>
            </tr>
            <tr style="color: #444; ">
              <td
                width="47%"
                align="left"
              >
                слева
              </td>
              <td
                width="6%"
                style="text-align: center;"
              >
&nbsp;
              </td>
              <td
                width="47%"
                align="right"
              >
                справа
              </td>
            </tr>
            <tr>
              <td
                width="47%"
                align="right"
              >
                {{ d.bu_Slave0Count }}
              </td>
              <td
                width="6%"
                style="text-align: center;"
              >
                &divide;
              </td>
              <td width="47%">
                {{ d.bu_Slave1Count }}
              </td>
            </tr>
            <tr>
              <td
                colspan="3"
                align="center"
              >
&nbsp;
              </td>
            </tr>
            <tr>
              <td
                colspan="3"
                align="center"
              >
                <br><b>Контрактов за период c {{ d.startDate }}</b><hr>(Оплачено)<br>
              </td>
            </tr>
            <tr>
              <td
                width="47%"
                align="right"
              >
                {{ d.cntAll0 }}
              </td>
              <td
                width="6"
                style="text-align: center;"
                align="center"
              >
                &divide;
              </td>
              <td width="47%">
                {{ d.cntAll1 }}
              </td>
            </tr>
            <tr>
              <td
                colspan="3"
                align="center"
              >
                <b>Всего ЛП</b><hr>
              </td>
            </tr>
            <tr>
              <td
                width="47%"
                align="right"
              >
                {{ d.bu_NewPerNum0 || 0 }}
              </td>
              <td
                width="6%"
                style="text-align: center;"
                align="center"
              >
                &divide;
              </td>
              <td width="47%">
                {{ d.bu_NewPerNum1 || 0 }}
              </td>
            </tr>
          </table>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
/* eslint-disable */
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BOverlay,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BOverlay,
  },
  props: {
    data: {
      type: Object,
      required: false,
      default: () => {},
    },
    api: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const d = ref(null)
    const loading = ref(false)

    if (props.api) {
      loading.value = true
      store.dispatch('app/fetchData', props.api).then(res => {
        const { data, success } = res.data

        if (success) {
          d.value = data[0]
        }

        loading.value = false
      }).catch(() => {
        loading.value = false
      })
    }

    return {
      d,
      loading,
    }
  },
}
</script>
<style scoped>
  .statlegend b {
    font-size: 1.285rem;
    font-weight: 500;
    color: #181818;
  }
</style>
