<template>
  <b-card
    class="card-promo"
  >
    <b-overlay
      :show="loading"
      no-wrap
      :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
    />
    <b-form-group
      label="Реферальная ссылка для регистрации в ЛЕВУЮ ветку"
      label-for="promo-link"
    >
      <b-input-group
        class="input-group-merge"
      >
        <b-form-input
          id="promo-link"
          v-model="d.link"
        />
        <b-input-group-append is-text>
          <b-button
            variant="outline-primary"
            size="sm"
            @click="doCopy(d.link)"
          >
            Скопировать
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
    <b-form-group
      label="Реферальная ссылка для регистрации в ПРАВУЮ ветку"
      label-for="promo-link2"
      class="input-group-merge"
    >
      <b-input-group
        class="input-group-merge"
      >
        <b-form-input
          id="promo-link2"
          v-model="d.link2"
        />
        <b-input-group-append is-text>
          <b-button
            variant="outline-primary"
            size="sm"
            @click="doCopy(d.link2)"
          >
            Скопировать
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
    <div class="mt3">
      <b-link
        :to="{ name: 'how-use-refer' }"
      >
        Как пользоваться реферальной ссылкой
      </b-link>
    </div>
    <!-- <qrcode
      v-if="qr"
      :value="qr"
      :options="{ width: 150 }"
    ></qrcode> -->
    <!-- <b-form-group
      label="Ваш промокод"
      label-for="promo-code"
      class="input-group-merge"
    >
      <b-input-group
        class="input-group-merge"
      >
        <b-form-input
          id="promo-code"
          v-model="d.code"
        />
        <b-input-group-append is-text>
          <b-button
            variant="outline-success"
            size="sm"
            @click="doCopy(d.code)"
          >
            Скопировать
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group> -->
    <template #footer>
      <div class="d-flex justify-content-between">
        <b-button
          v-b-modal="'sp-modal-form'"
          variant="primary"
        >
          Форма регистрации
        </b-button>
        <b-button
          variant="outline-primary"
          :href="d.linktosite"
          target="blank"
        >
          {{ d.titlelinktosite }}
        </b-button>
      </div>
    </template>
  </b-card>
</template>
<script>
/* eslint-disable */
import {
  BCard,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BLink,
  BImg,
  BOverlay,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref } from '@vue/composition-api'
import store from '@/store'

export default {
  components: {
    BCard,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BLink,
    BImg,
    BOverlay,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    api: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup(props, ctx) {
    const doCopy = txt => {
      ctx.root.$copyText(txt).then(() => {
        ctx.root.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ссылка скопирована',
            icon: 'BellIcon',
          },
        })
      }, () => {
        ctx.root.$toast({
          component: ToastificationContent,
          props: {
            title: 'Не удалось скопировать ссылку',
            icon: 'BellIcon',
          },
        })
      })
    }

    const d = ref(JSON.parse(JSON.stringify(props.data)))
    const loading = ref(false)

    if (props.api) {
      loading.value = true
      store.dispatch('app/fetchData', props.api).then(res => {
        const { data, success } = res.data

        if (success) {
          d.value = data[0]
        }

        loading.value = false
      }).catch(() => {
        loading.value = false
      })
    }

    const qr = ref(null)

    // store.dispatch('app/fetchData', 'https://api.hoglabest.com/api/v1/json/lk/dashboad/data/page/qr').then(res => {
    //   const { data, success } = res.data

    //   if (success) {
    //     qr.value = data.qrcode
    //   }
    // })

    return {
      doCopy,
      d,
      qr,
      loading,
    }
  },
}
</script>
<style scoped>
  .input-group-text {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 5px;
  }
</style>
<style>
.card-promo .card-body {
  padding-bottom: 0;
}
.card-promo .card-footer {
  border-top: none!important;
  padding-top: 0;
}
</style>
