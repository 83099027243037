        <template>
  <div
      >
    <b-row
              class="match-height"
          >
                                    <b-col
                                                          lg="7"
                                                                    >
                            <main-info
  :data="{&quot;name&quot;:&quot;-&quot;,&quot;enter&quot;:&quot;-&quot;,&quot;date&quot;:&quot;-&quot;,&quot;status&quot;:&quot;-&quot;,&quot;count&quot;:&quot;-&quot;,&quot;status2&quot;:&quot;-&quot;,&quot;active&quot;:&quot;-&quot;}"
  api="https://api.hoglabest.com/api/v1/json/lk/dashboad/data/page/maininfo"
/>            </b-col>
                                        <b-col
                                                          lg="5"
                                                                    >
                            <promo
  :data="{&quot;link&quot;:&quot;&quot;,&quot;link2&quot;:&quot;&quot;,&quot;code&quot;:&quot;&quot;}"
  api="https://api.hoglabest.com/api/v1/json/lk/dashboad/data/page/promo"
/>            </b-col>
                                        <b-col
                                                          lg="4"
                                                                    >
                            <ProfitBalance
  api="https://api.hoglabest.com/api/v1/json/lk/dashboad/data/page/profit"
    />            </b-col>
                                        <b-col
                                                          lg="3"
                                                                    >
                            <html-content
  api="https://api.hoglabest.com/api/v1/htm/lk/dashboad/data/page/marketing"
    :innerHTML="true"
  />            </b-col>
                                        <b-col
                                                          lg="5"
                                                                    >
                            <messages
  api="https://api.hoglabest.com/api/v1/json/lk/dashboad/data/page/msg"
/>            </b-col>
                                        <b-col
                                                                                                >
                            <sp-modal-form
            sendApi="https://api.hoglabest.com/api/v1/json/lk/buyer/contract/add"
            method="post"
            getApi=""
            id="sp-modal-form"
            title="Добавить партнера"
    />
            </b-col>
                            </b-row>
  </div>
</template>
<script>
/* eslint-disable */
// prettier-ignore
import {
  BRow,
  BCol,
} from 'bootstrap-vue'

import { ref } from '@vue/composition-api'

                                      import MainInfo from '@core/components/dashboard/MainInfo.vue'
                                                              import Promo from '@core/components/dashboard/Promo.vue'
                                                              import ProfitBalance from '@core/components/dashboard/ProfitBalance.vue'
                                                              import HtmlContent from '@core/components/html-content/HtmlContent.vue'
                                                              import Messages from '@core/components/messages/Messages.vue'
                                                              import SpModalForm from '@core/components/sp/sp-modal-form/SpModalForm.vue'
                            
export default {
  components: {
    BRow,
    BCol,

                                                          MainInfo,
                                                                              Promo,
                                                                              ProfitBalance,
                                                                              HtmlContent,
                                                                              Messages,
                                                                              SpModalForm,
                                          },
  setup() {
              
          return {
            }
      },
}
</script>
    